<script setup lang="ts">
import { AERIAL_VERTICAL } from '~/constants/pictures'

useMetaDefaultHead()
useSeoMetaLinksAlternate()
useSeoDefault({ imagePath: AERIAL_VERTICAL })
</script>

<template>
  <div
    class="from-peacock-500 to-peacock-400 w-screen bg-gradient-to-tr bg-contain bg-fixed bg-right bg-no-repeat"
    :data-twic-background="`url('image:${AERIAL_VERTICAL}')`"
  >
    <YscLoadingBar />

    <div class="z-10 h-screen bg-white lg:w-7/12">
      <header class="z-50 w-full">
        <div class="flex h-16 items-center justify-between px-4 lg:h-20">
          <div class="flex items-center">
            <AppLogo />
          </div>
        </div>
      </header>

      <main class="container relative py-16">
        <div class="mx-auto md:w-2/3">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
